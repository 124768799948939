import logo from "./logo.png"

const clientData = {
    client_entity: 22,
    attorney_firm: 'Lowery, Payn & Leggett',
    attorney_name: 'James Bellipanni',
    attorney_number: '(601) 833-1456',
    attorney_email: 'james@lplcpas.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_lowery%2C_payn_%26_leggett+(720p).mp4',
    mainColor: ' #1d558d',
    secondaryColor: '#2B3055',
    siteLink: 'http://lplcpas.com',
    logo
}

export default clientData